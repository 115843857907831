<template>
  <div class="videos--categories mb-16">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Video Categories</h2>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :to="{ name: 'videos' }"
            depressed
            text
          >
            <span> Save Video </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <video-category-form
          :exclude="categories"
          @category-added="categoryAdded"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <video-category-list class="mt-5" :items="categories" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VideoCategoryForm from '../components/VideoCateogoryForm'
import VideoCategoryList from '../components/VideoCategoryList'
import Category from '@/models/Category'
import Video from '@/models/Video'

import { mapGetters, mapMutations } from 'vuex'
import { mdiArrowRight } from '@mdi/js'
import { map } from 'lodash'

export default {
  name: 'VideoCategories',

  components: {
    VideoCategoryForm,
    VideoCategoryList,
  },

  data() {
    return {
      categories: [],

      icons: {
        next: mdiArrowRight,
      },
    }
  },

  created() {
    if (this.$attrs.id) {
      this.getVideo()
    }
  },

  computed: {
    ...mapGetters({
      video: 'videos/selectedVideo',
    }),
  },

  methods: {
    ...mapMutations({
      setSelectedVideo: 'videos/setSelectedVideo',
    }),

    async getVideo() {
      let { data } = await Video.include([
        'categories.ancestors',
        'equipments',
        'programs',
        'tags',
      ]).find(this.$attrs.id)

      if (data) {
        this.setSelectedVideo(data)

        this.categories = map(
          data.categories,
          (category) => new Category(category)
        )
      }
    },

    categoryAdded(category) {
      this.categories.push(category)
    },
  },
}
</script>
