<template>
  <div class="bbr-video--contents-categories-form">
    <v-row>
      <v-col cols="12" :sm="form.is_pinned ? 8 : 12">
        <v-layout row>
          <v-flex xs1 justify-center align-center>
            <v-subheader>Video Categories</v-subheader>
          </v-flex>
          <v-flex xs1 justify-center>
            <v-switch v-model="toggleFilter"></v-switch>
          </v-flex>
          <v-flex xs1 justify-center align-center>
            <v-subheader>Filter Categories</v-subheader>
          </v-flex>
        </v-layout>
        <category-search-input
          v-model="form.category"
          :label="categorySearch.label"
          :placeholder="categorySearch.placeholder"
          :slug="categorySearch.slug"
          category-search-type="descendants"
          :exclude-items="exclude"
          :error-messages="form.$getError('category_id')"
          :hide-details="!form.$getError('category_id')"
          return-object
          hide-selected
          clearable
          no-filter
          outlined
          flat
        />
      </v-col>

      <v-col v-if="form.is_pinned" cols="12" sm="4">
        <v-select
          v-model="form.order"
          label="Video Pinning Order"
          :error-messages="form.$getError('order')"
          :hide-details="!form.$getError('order')"
          :items="pinningOrders"
          item-value="id"
          item-text="name"
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" class="d-flex align-center">
        <v-checkbox
          v-model="form.is_pinned"
          label="Pinned Video?"
          color="primary"
          hide-details
          class="py-0 my-0"
        />

        <v-spacer />

        <v-btn
          color="primary"
          class="px-10"
          :loading="form.$busy"
          @click="attachCategory"
        >
          Attach Category
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CategorySearchInput from '@/components/elements/categories/CategorySearchInput'

import Category from '@/models/Category'
import From from '@/utils/form'

import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { map } from 'lodash'

export default {
  name: 'VideoCategoryForm',

  directives: {
    mask,
  },

  components: {
    CategorySearchInput,
  },

  props: {
    exclude: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      categorySearch: {
        label: 'Video Category',
        placeholder: 'Search Video Category',
        slug: 'video',
      },
      form: new From({
        category: {},
        order: null,
        is_pinned: false,
        category_id: null,
      }),
      toggleFilter: false,
    }
  },

  computed: {
    ...mapGetters({
      video: 'videos/selectedVideo',
    }),

    pinningOrders() {
      let maxCount = Number(this.$env.pinnedVideosCount)

      return map([...Array(maxCount).keys()], (order) => {
        let _order = order + 1

        return {
          id: _order,
          name: _order,
        }
      })
    },
  },

  methods: {
    ...mapActions({
      attachVideoCategory: 'videos/attachVideoCategory',
    }),

    async attachCategory() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.form.category_id = this.form.category.id

      this.attachVideoCategory({
        form: this.form,
        video: this.video,
      })
        .then(({ data }) => {
          this.form.$timeout(() => {
            this.form.$busy = false
            this.form.$reset()

            this.$emit('category-added', new Category(data.data))
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }
          })
        })
    },
  },

  watch: {
    is_pinned(value) {
      if (!value) {
        this.order = null
      }
    },
    toggleFilter(val) {
      if (val) {
        this.categorySearch = {
          label: 'Ondemand Filter',
          placeholder: 'Search Ondemand Filter',
          slug: 'ondemand-filtering',
        }
      } else {
        this.categorySearch = {
          label: 'Video Category',
          placeholder: 'Search Video Category',
          slug: 'video',
        }
      }
    },
  },
}
</script>
