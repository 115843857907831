<template>
  <v-card class="videos--categories-list" outlined>
    <template v-if="hasItems">
      <template v-for="(category, index) in categories">
        <v-card-text :key="category.slug">
          <div class="d-flex align-center">
            <div>
              <h3 class="primary--text">
                {{ category.name }}

                <span class="caption grey--text ml-2">
                  {{ category.ancestors | readable(' / ', 'name') }}
                </span>
              </h3>

              <span
                v-if="category.categorizable.order"
                class="caption grey--text"
              >
                Pinned Order: {{ category.categorizable.order }}
              </span>
            </div>

            <v-spacer />

            <v-btn color="primary" @click="removeCategory(category)" text>
              Remove
            </v-btn>
          </div>
        </v-card-text>

        <v-divider
          v-if="index + 1 < itemCount"
          :key="`category-list-${category.slug}-divider`"
        />
      </template>
    </template>

    <no-list v-else details="No Category List" />

    <confirm-dialog ref="confirmRemoveCategory" />
  </v-card>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import NoList from '@/components/elements/NoList'

import { mdiPin, mdiPinOffOutline } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import { findIndex } from 'lodash'

export default {
  name: 'VideoCategoryList',

  components: {
    ConfirmDialog,
    NoList,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      categories: [],

      icons: {
        pinned: mdiPin,
        notPinned: mdiPinOffOutline,
      },
    }
  },

  computed: {
    ...mapGetters({
      video: 'videos/selectedVideo',
    }),

    hasItems() {
      return !!this.items.length
    },

    itemCount() {
      return this.items.length
    },
  },

  created() {
    this.categories = this.items
  },

  methods: {
    ...mapActions({
      updateVideoCategory: 'videos/updateVideoCategory',
      removeVideoCategory: 'videos/removeVideoCategory',
    }),

    async pinCategory(category) {
      let value = !category.is_pinned

      let { data } = await this.updateVideoCategory({
        video: this.video,
        form: {
          category_id: category.id,
          is_pinned: value,
          order: category.order,
        },
      })

      category.is_pinned = data.data.is_pinned
    },

    async removeCategory(category) {
      const confirm = await this.$refs.confirmRemoveCategory.open(
        'Remove Category',
        'Are you sure you want to remove this category to video?'
      )

      if (!confirm) return

      await this.removeVideoCategory({
        video: this.video,
        category,
      })

      this.categories.splice(findIndex(this.categories, ['id', category.id]), 1)
    },
  },

  watch: {
    items(values) {
      this.categories = values
    },
  },
}
</script>
