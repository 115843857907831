<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="categories"
    :hide-no-data="hideNoData"
    :items="selectedCategories"
    :search-input.sync="search"
    @click:clear="clearCategoryInput"
    item-text="name"
    item-value="id"
  >
    <template v-if="isDescendantCategoryType" v-slot:selection="data">
      <v-chip
        v-if="chips"
        class="mt-3 text-capitalize"
        v-bind="data.attrs"
        @click:close="removeCategory(data.index)"
        close
      >
      </v-chip>

      <span v-else>
        <span v-if="data.item.ancestors">
          {{ data.item.ancestors | readable(' / ', 'name') }} /
        </span>
        {{ data.item.name }}
      </span>
    </template>

    <template v-if="isDescendantCategoryType" v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.ancestors | readable(' / ', 'name') }} /
          {{ data.item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import Category from '@/models/Category'

import {
  map,
  uniqBy,
  filter,
  includes,
  debounce,
  isEmpty,
  isArray,
} from 'lodash'
import { mapActions } from 'vuex'

export default {
  name: 'CategorySearchInput',

  props: {
    chips: Boolean,
    value: [Array, Object],
    excludeItems: Array,
    slug: {
      type: String,
      default: 'video',
    },
    defaultValue: [Object, Array],

    categorySearchType: {
      type: String,
      required: true,
      validator: (value) => {
        const types = ['descendants']

        return includes(types, value)
      },
    },
  },

  data() {
    return {
      search: '',
      loading: false,

      categories: [],
      searchedCategories: [],
      categorySearchTypes: ['descendants'],
    }
  },

  computed: {
    isDescendantCategoryType() {
      return this.categorySearchType === 'descendants'
    },

    selectedCategories() {
      let categories = this.categories ?? []
      let excludes = map(this.excludeItems, 'id')

      if (!isArray(categories)) {
        categories = [categories]
      }

      categories = uniqBy([...categories, ...this.searchedCategories], 'id')

      return filter(categories, (category) => {
        return !includes(excludes, category.id)
      })
    },

    hideNoData() {
      return isEmpty(this.search) && isEmpty(this.selectedCategories)
    },
  },

  mounted() {
    this.categories = map(this.value, (value) => {
      return new Category(value)
    })
  },

  methods: {
    ...mapActions({
      getCategoryDescendants: 'categories/getCategoryDescendants',
    }),

    searchCategories: debounce(async function() {
      await this.fetcVideoCategories().then(({ data }) => {
        this.searchedCategories = data
      })
    }, 250),

    fetcVideoCategories(page = 1, slug = this.slug) {
      let search = this.search
      let includes = ['ancestors']
      if (this.isDescendantCategoryType) {
        return this.getCategoryDescendants({ page, slug, search, includes })
      }
    },

    removeCategory(index) {
      this.categories.splice(index, 1)
    },

    clearCategoryInput() {
      this.categories = []
    },
  },

  watch: {
    search(val) {
      if (val) {
        this.searchCategories(val)
      }
    },

    categories(values) {
      this.$emit('input', values)
    },

    value(values) {
      this.categories = values
    },
  },
}
</script>
